
.container {
    position: relative;
    /* background-color: #161b22; */
    background-color: #1a2435 !important;
    padding: 2vh 1.5vw;
    margin: 2vh 0vw;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 30%;
    min-height: 60vh;
    color: white;
  }
  .image-container {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .image-container img {
    width: clamp(1vh,12vw,40vh);
    object-fit: contain;
    border-radius: 1rem;
  }
  .course-title-h1 {
    font-size: clamp(0.5rem,1.8rem,100rem);
    margin-bottom: 3vh;
    margin-top: 7rem;
    /* text-align: left; */
  }
  .course-title-p {
    font-size: clamp(0.5rem,1.2rem,100rem);
    margin-bottom: 2vh;
    /* text-align: left; */
    /* word-spacing: 0px; */
  }
  .card-title-link{
    text-decoration: none;
    color: whitesmoke;
  }
 


/* Tablet Styles */
@media screen and (max-width: 768px) {
    h1 {
        margin-bottom: 2vh;
        margin-top: 3rem;
      }

      .container {
        padding: 2vh 1.5vw;
        margin: 5vh 0vw;
        width: 80%;
      }

      .image-container img {
        width: clamp(1vh,18vw,40vh);
      }
}

/* Laptop Styles */
@media screen and (min-width: 1024px) {
  
    .image-container {
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
      }
    .image-container img {
        width: clamp(1vh,11vw,40vh);
        object-fit: contain;
        border-radius: 1rem;
      }
}

/* Large TV/Desktop Styles */
@media screen and (min-width: 1920px) {
   
    .image-container {
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
      }
    .image-container img {
        width: clamp(1vh,7vw,40vh);
        object-fit: contain;
        border-radius: 1rem;
      }
}