header{
    background-color:rgba(0,0,0,.4);
    margin: 0;
    padding: 3vh 7vw 3vh 7vw;
}


.headerLogo{
    width: clamp(0.5rem, 6vw,20rem);
    display: flex;
    
}
p{
    color: whitesmoke;
    margin: 0;
    padding-bottom: 1.3vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-shadow: #666;
    opacity: 0.7;
}

.logo-text{
  font-size: clamp(0.5rem,1.2rem,100rem);
   text-align: end;

   color: whitesmoke;
    margin: 0;
    padding-bottom: 1.3vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-shadow: #666;
    opacity: 0.7;
}

.headerLogoImg{
    width: 100%;
}


  .headerNav {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: .5rem 2.4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: clamp(0.5rem, 1.4vw,20rem);
  }



.headerMenu{
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    /* gap: 1.2rem; */
    text-decoration: none;
    color: white;
}

.Paths,.certifications,.Modules,.Business,.labs,.Faq,.News{
    color: white;
    text-decoration: none;
    transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1), color 0.4s ease-in-out;
}

.Paths:hover,.certifications:hover,.Modules:hover,.Business:hover,.labs:hover,.Faq:hover,.News:hover{
    color: rgb(83, 154, 235);
    background-color: whitesmoke;
    /* text-decoration: wavy; */
    border-radius: 1rem;
    padding: 1vh 1vw;
    transform: scale(1.15);
}



.title-container{
    display: flex;
    justify-content: center;
}

.title-container-content{
    /* color: white;*/
    font-size: clamp(0.5rem,5vw,30rem); 

    color: hsl(0, 0%, 28%);
    font-weight: bold!important;
    font-family: monospace;
    letter-spacing: 0.5rem!important;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .title-container-content {
    padding: 1vh 1vw 1vh 1vw;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
  }


  @keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 600px;
    }
  }



.training-list-section{
    background-color:rgba(0,0,0,.4);
}

.training-list-container{
    padding: 4vh 2vw 4vh 2vw;
}

.training-list-container-row1,.training-list-container-row2,.training-list-container-row3{
    display: flex;
    justify-content: space-evenly;
  margin: 25vh 2vw 6vh 2vw;
}



.footer{
    padding: 1vh 2vw;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .footer-container{
    text-align: center;
  }
  
  .row-1{
    display: flex;
    flex: 1;
    text-align: left;
    justify-content: space-around;
    padding: 2vh 1vw;
  }
  
  .footer-container-col h4{
    font-size:  clamp(0.5rem, 1.3vw, 20rem); 
    
  }
  
  .footer-container-col ul{
    font-size:  clamp(0.5rem, 1.2vw, 20rem); 
    
  }
  
  .row-3,.rights-reserved{
    display: flex;
    justify-content: space-between;
    padding: 2vh 2.5vw;
  }
  
  .rights-reserved{
    font-size:  clamp(0.5rem, 1.2vw, 20rem); 
    color: #adacac;
  }
  .footer-links{
    text-decoration: none;
    color: #adacac;
  }
  
  .footer,ul {
    list-style-type: none;
    /* or */
    list-style-image: none;
    /* or */
    list-style: none;
    padding: 0%;
  }
  
  ul{
    color: #adacac;
  }
  .row-2{
    padding: 0vh 5vw;
  
  }
  .vertical-line {
    height: 0.5vh;
    background-color: #666; /* Change the color as desired */
  }
  


  @media screen and (max-width: 768px) {
    .training-list-container-row1,.training-list-container-row2,.training-list-container-row3{
      flex-direction: column;
      align-items: center;
      margin: 0vh 2vw 6vh 0vw;
  }

  .headerLogoImg{
    width: clamp(1vh,6vw,30vh);
  }

  .logo-text{
    font-size: clamp(0.1rem,0.6rem,100rem);
    padding-bottom: 0.3vh;
  }


  }