.TrainingDetailsPageSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.TrainingDetailsPageSectionrow1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details-section {
    flex: 1;
    padding: 3vh 2vw;

    /* background-color:rgba(0,0,0,.4); */
}

.details-section-Img {
    flex: 1;
    text-align: center;
}

.training-image {
    width: clamp(0.5vh,32vw,50vh);
    height: auto;
}

.TrainingDetailsPageSectionrow2 {
    padding: 2vh 2vw;
    border-radius: 1vw;
    background-color:rgba(0,0,0,.4);
}

.summary-tab .tab-label {
    font-size: clamp(0.5rem,2vw,20rem);
    color: whitesmoke;
    text-decoration: underline;
    /* margin-bottom: 10px; */
}

.sections-list {
    list-style: none;
    padding: 0;
}

.section-item {
    margin-bottom: 10px;
}

.section-toggle {
    display: flex;
    /* justify-content: space-evenly; */
    width: 50%;
    background: rgb(184, 184, 192,0.5);
    border-radius: 0.5vw;
    border: none;
    font-size: clamp(0.5rem,1.2vw,20rem);
    padding: 1vh 5vw 1vh 2vw;
    cursor: pointer;
}

.section-data {
    display: none;
    
    padding: 2vh 2vw 1vh 5vw;
    font-size: clamp(0.5rem,1.2vw,20rem);
    list-style: disc;
}

.section-item.expanded .section-data {
    display: block;
}

.arrow {
    transform: rotate(0deg);
    transition: transform 0.3s;
}

.section-item.expanded .arrow {
    transform: rotate(180deg);
}

.title{
    font-size:  clamp(0.5rem, 4vw , 20rem);
    color: whitesmoke;
    /* background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear; */
    margin: 3vh 0vw;
}

.description{
    font-size:  clamp(0.5rem, 1.6vw , 20rem);
}